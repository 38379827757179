import logo from './logo.svg';
import './App.css';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Button, Drawer } from '@mui/material';
// import { CircleLoader } from "react-awesome-loaders";



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";
import { BallTriangle } from 'react-loader-spinner';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBcaLiWfuD4YZhMwWxMCQ_cmXGj4ye8BAc",
  authDomain: "adpage-735e8.firebaseapp.com",
  projectId: "adpage-735e8",
  storageBucket: "adpage-735e8.appspot.com",
  messagingSenderId: "395298103790",
  appId: "1:395298103790:web:43fc413763e504ddd5eeff",
  measurementId: "G-NH67JGSZ7N"
};

// Initialize Firebase
const appl = initializeApp(firebaseConfig);
const analytics = getAnalytics(appl);

function App() {

  const [cData,setCdata]= useState(null);
  const [count,setCount]=useState(0);
  const [bmodal,setbmodal]=useState(false);
  const cRef= useRef(0);
  useEffect(() => {
    let mes= "history added"
    let adhistory=localStorage.getItem("adhistory");
   
var data = '';
let pathhere = window.location.pathname;
let bindex= pathhere.indexOf("adaqua.in")+2;
let bottleId= pathhere.slice(bindex,-2);
let campId= pathhere.slice(-1);
// alert(`page here is Id ${bottleId} , camp  is ${campId}`);
var config = {
  method: 'get',
  url: `https://41v51env05.execute-api.ap-south-1.amazonaws.com/default/public/qr/${bottleId}/${campId}`,
  headers: { },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
  setCdata(response.data);
  if(response.data.camp.tracking){
    if (adhistory && JSON.parse(adhistory).hasOwnProperty("retailers")) {
      // alert("Ad history found in localstorage:"+adhistory);
      let adhis= JSON.parse(adhistory);
      if(adhis.retailers.hasOwnProperty(response.data.retailer)){
        if(adhis.retailers[response.data.retailer].campaigns.hasOwnProperty(response.data.camp._id)){
          adhis.retailers[response.data.retailer].campaigns[response.data.camp._id].count+=1;
          if(adhis.retailers[response.data.retailer].campaigns[response.data.camp._id].count>4){
            // alert("FAKE FOUND");
            let config5 = {
              method: 'get',
              url: `https://41v51env05.execute-api.ap-south-1.amazonaws.com/default/public/fake/${response.data.retailer}/${1}`,
              headers: { },
              data : data
            };
            
            axios(config5)
            .then(function (fres) {console.log("response",fres.data)});
          }
        }else{
          adhis.retailers[response.data.retailer].campaigns[response.data.camp._id]={count:1,time:Date.now()};
        }
      }else{
        adhis.retailers[response.data.retailer]={campaigns:{[response.data.camp._id]:{count:1,time:Date.now()}}};
      }
      localStorage.setItem('adhistory',JSON.stringify(adhis));
      
    }else{
      // alert("NO FOUND");
      let adhis= {retailers:{[response.data.retailer]:{campaigns:{[response.data.camp._id]:{count:1,time:Date.now()}}}}};
      localStorage.setItem("adhistory",JSON.stringify(adhis));
      
    }

    logEvent(analytics,"page_view",{ page_location:`/${response.data.camp._id}/ret_${response.data?.retailer ? response.data.retailer:""}`,
       page_path: `/${response.data.camp._id}/ret_${response.data?.retailer ? response.data.retailer:""}`,
       page_title:`${response.data.camp.name}` })
  }
  if(!response.data.camp.banner){
   
    startCount(response.data.camp.duration,response.data);
    console.log("wrong countdown hERERERERE");
  }else{
    setbmodal(true);
  }

})
.catch(function (error) {
  console.log(error);
});

  
  }, []);

const startCount =(sec,data)=>{
  let timerId=setInterval(()=>{
    // console.log("HERE IN TIMER",cRef.current,sec-cRef.current);
    if(cData && sec-cRef.current > 0){
      setCount(pre=>pre+1);
      cRef.current++;
    }else{
      linkme(data);
      clearInterval(timerId);
    }
  },1000)
}



const linkme=(dhere)=>{
  const data = cData? cData:dhere;
  const userAgent = navigator.platform;
  console.log("useragent",userAgent);
  let urlhere= data.url;
  if(data.camp.specialurl){
    if (userAgent.match(/Android|Linux|arm/i)) {
      console.log('Running on Android');
      urlhere=data.aurl;
      // Your Android-specific code here
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      console.log('Running on iOS');
      urlhere=data.iurl;
      // Your iOS-specific code here
    }
  }
  
    
    // window.open(urlhere) || window.location.assign(urlhere);
    window.location.href = urlhere ;
  
  // window.open(`http://${cData.url}`,"_blank") ;
  // console.log("redirect ui",cData.url);
  // window.location.assign(cData.url,"_blank") ;
}
  
  return (
    <div className="App">
      {/* <h2>Ad Waters</h2> */}
      <div className='headhere'>H2O ADVERTS PVT LTD</div>
      <div className='logohere'></div>
      <p className='offerContent'>
       {cData ? cData.camp.campMsg : null}
      </p>

      <div className='offerButton'><button onClick={()=>{ if(cData)linkme();}}>APPLYING OFFER IN <strong>{cData? cData.camp.duration-count+1:0}</strong> seconds</button></div>
      
      <Drawer
      open={bmodal}
      variant='temporary'
      anchor='bottom'
      // onClose={()=>setbmodal(false)}
      >
        <div className='consentmodal'>
            <div className='consentinfo'>We use cookies to track website anlaytics,facilitate discounts by redirecting and other advertising purposes.Please <span style={{color:"blue"}}>click here</span> to learn more about our privacy policy</div>
            <div className='consentBtns'>
                <Button variant='contained' onClick={()=>{startCount(cData.camp.duration);setbmodal(false);}}>Accept</Button>
                <Button color='error' onClick={()=>{startCount(cData.camp.duration);setbmodal(false);}}>Decline</Button>
            </div>
        </div>
        
      </Drawer>
      {/* <div className='cookieBanner' style={{display:bmodal? "flex":"none"}}>
        <button onClick={()=>{startCount(cData.camp.duration);setbmodal(false);}}>accept</button>
      </div> */}
      <div className='loaderhere' style={{display:cData? "none":"flex"}}>
      <div className='headherext'>H2O ADVERTS PVT LTD</div>
      <div className='logoherextended'></div>
      <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="black"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
  />
  <div className='loadertext'><div>Applying Coupon</div> <div className='subtxt'>Please Wait.</div></div>


      </div>
    </div>
  );
}

export default App;
